@import "../../styles/variables";

.card-link {
  color: $primary-10;

  .contact-icon {
    font-size: 18px;
    color: $primary-6;
    background-color: $primary-1;
    padding: 11px;
    border-radius: 50%;

    .h {
      margin-left: 16px;
      font-size: 20px;
      line-height: 28px;
    }

    @media only screen and (max-width: 1279px) {
      display: none;

      .h {
        margin-left: 0;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.card-hint {
  color: $text-socondary;
}
