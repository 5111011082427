@import "./variables.scss";

body {
  background-color: $white-1;
}

.page-content {
  height: calc(100% - 104px);
  background-color: $grey-2;

  @media only screen and (max-width: 1279px) {
    height: calc(100% - 64px);
  }
}

.content {
  padding-top: 32px;

  .header {
    font-size: 32px;
    margin: 0;
  }
}

.header {
  font-family: "Roboto Slab";
  font-weight: 500;
}

.h {
  font-weight: 500;
  margin: 0;

  &.h1 {
    font-size: 38px;
    line-height: 46px;
  }

  &.h2 {
    font-size: 30px;
    line-height: 40px;
  }

  &.h3 {
    font-size: 24px;
    line-height: 32px;
  }

  &.h4 {
    font-size: 20px;
    line-height: 28px;
  }

  &.h5 {
    font-size: 16px;
    line-height: 24px;
  }
}

.text {
  font-size: 16px;
  line-height: 24px;

  &.w-500 {
    font-weight: 500;
  }

  .text-primary {
    color: $text-primary;
  }
}

.text-md {
  font-size: 14px;
  line-height: 20px;

  &.w-500 {
    font-weight: 500;
  }

  &.title {
    color: $text-socondary-2;
  }
}

.text-sm {
  font-size: 12px;
  line-height: 16px;

  &.title {
    color: $text-socondary-2;
  }

  &.w-500 {
    font-weight: 500;
  }

  &.w-400 {
    font-weight: 400;
  }
}

.input__container {
  position: relative;
}

.normal {
  font-weight: 400 !important;
}

.ant-input,
.ant-input-password {
  &.ant-input-lg,
  &.ant-input-password-large {
    border-radius: 4px;
  }
}

.ant-btn-link {
  color: $primary-6;

  &.no-decoration {
    text-decoration: none;
  }
}

.ant-form-item-explain-error {
  font-size: 12px;
}

.ant-form-item-label {
  padding: 0 0 2px !important;
  label {
    width: 100%;
    display: block !important;
  }
}

.underline {
  text-decoration: underline;

  > span {
    text-decoration: underline;
  }
}

.height-0 {
  height: unset;
}

.elipse {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: $grey-5;
}

.sub-title__container {
  margin-left: 40px;
  p {
    display: flex;
    color: $primary-10;
    line-height: 20px;
    letter-spacing: 0.25px;
    > .email-text {
      font-weight: 500;
    }

    @media only screen and (max-width: 1279px) {
      display: block;
      text-align: center;
    }
  }

  @media only screen and (max-width: 1279px) {
    margin-left: 0px;
  }
}

p {
  letter-spacing: 0.25;

  &.ls-5 {
    letter-spacing: 0.5px;
  }
}

.form__container {
  min-width: 360px;

  @media only screen and (max-width: 1279px) {
    min-width: unset;
    width: 100%;
  }
}

.ant-input-group-addon {
  border-radius: 4px 0 0 4px !important;
}

.ant-table-thead {
  .ant-table-cell {
    padding: 11px 16px !important;

    @media only screen and (max-width: 1200px) {
      padding: 11px 12px !important;
    }

    @media only screen and (max-width: 359px) {
      font-size: 11px;
    }
  }
}

.ant-table-tbody {
  .ant-table-cell {
    padding: 6px 16px !important;
  }
}

.loader__container {
  height: 100%;

  .loader {
    font-size: 64px;
    color: $primary-6;
  }
}

.logo-cursor {
  cursor: pointer;
}

.logged-in__page {
  height: calc(100% - 104px);

  @media only screen and (max-width: 1279px) {
    height: calc(100% - 64px);
  }
}

.ant-tabs {
  width: 100%;

  .ant-tabs-nav {
    padding-left: 32px;
    margin-bottom: 0;

    .ant-tabs-tab-btn {
      line-height: 20px;
    }
  }

  .ant-tabs-content-top {
    height: 100%;

    .ant-tabs-tabpane-active,
    .tab-content {
      height: 100%;
    }
  }
}

.table-page-content {
  height: calc(100% - 104px);

  @media only screen and (max-width: 1279px) {
    height: calc(100% - 85px);
  }

  .table__container {
    height: calc(100% - 128px);

    .ant-table-wrapper {
      height: calc(100% - 50px);
      .ant-table-thead,
      .ant-table-tbody {
        .ant-table-cell > a {
          line-height: 32px;
        }
      }

      .ant-table-thead {
        position: sticky;
        top: 0;
        z-index: 100;
      }
    }
  }
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pre-line {
  white-space: pre-line;
}

.ant-skeleton-paragraph {
  margin: 0;

  li {
    float: right;
  }
}

.error {
  color: $error;
}

.filters {
  .search-icon {
    color: $text-grey;
  }
}

.table-actions {
  .ant-btn {
    width: auto;
    height: 22px;
  }
}

.no-h {
  height: auto;
}

.icon-btn {
  height: unset;
  width: unset;
  border: unset;

  &:hover {
    background-color: transparent !important;
  }
}

.ant-tooltip-inner {
  color: $text-primary !important;
  min-height: auto !important;
}

.phone-input {
  > .ant-form-item {
    width: 100%;
    margin-bottom: 0 !important;
  }
}
