@import "../../styles/variables";

.notification__container {
  max-width: 520px;

  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background-color: $white-1;

  &.error {
    > .anticon {
      color: $red-6;
    }
  }

  &.success {
    > .anticon {
      color: $green-6;
    }
  }

  &.info {
    > .anticon {
      color: $primary-6;
    }
  }

  > .notification-content {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: $primary-10;
  }
}
