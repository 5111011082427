@import "../../../styles/variables";

.ant-layout-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white-1;

  @media only screen and (max-width: 1279px) {
    padding: 0 16px;
  }

  > span {
    color: $grey-1;
    padding-right: 8px;
  }

  .footer__container {
    width: 880px;
    border-top: 1px solid $grey-0;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    > span {
      margin-right: 8px;
      color: $grey-7;
    }

    .footer-link {
      @media only screen and (max-width: 1279px) {
        width: 100%;
        justify-content: center;

        > .elipse:first-child {
          display: none;
        }
      }

      .ant-btn-link {
        padding: 4px 8px;
        color: $grey-7;
      }
    }

    @media only screen and (max-width: 1279px) {
      width: 100%;
      display: block;
    }
  }
}
