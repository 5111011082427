@import "../../styles/variables";

.greeting__container {
  display: flex;

  .header {
    font-size: 30px;
    line-height: 40px;
    margin: 0;
  }

  .back-btn {
    color: $primary-10;
    background-color: $white-1;
  }

  @media only screen and (max-width: 1279px) {
    display: block;

    .header {
      text-align: center;
      margin-top: 16px;
    }
  }
}

.login-form {
  width: 400px;
}

.useful-links__container {
  align-items: start;

  .useful-links-list {
    &.items {
      padding-top: 10px;
    }

    .useful-link {
      padding: 0;

      > span {
        letter-spacing: 0.25px;
      }
    }
  }

  @media only screen and (max-width: 1279px) {
    margin-bottom: 100px;
  }
}

.check-links_ss-wrapper {
  .useful-links-list {
    max-width: 347px;

    @media only screen and (max-width: 1279px) {
      margin-bottom: 80px;
    }
  }
}

.no-account__container {
  align-items: center;

  .ant-btn-link {
    padding: 0 0 1px 6px;
  }

  @media only screen and (max-width: 1279px) {
    flex-direction: column;
    align-items: end;
  }
}

.check-links__container {
  width: 200px;
  position: absolute;
}

.check {
  &-form {
    > .ant-col {
      width: 880px;
      margin-bottom: 50px;
    }

    &__name {
      width: 203px;
    }

    @media only screen and (max-width: 1279px) {
      height: auto;

      > .ant-col {
        width: 400px;
        margin: 0 16px 75px;
      }
    }
  }
}
