@import "../../styles/variables";

.home-content {
  height: calc(100% - 80px);
  background-color: $grey-2;
  border-top: 1px solid $grey-0;

  > div {
    width: 1216px;

    .dashboard-card {
      border-radius: 8px;

      &.top {
        .ant-card-body {
          height: 416px;
        }
      }

      &.payments {
        .ant-card-body {
          height: 128px;
        }
      }
    }
  }

  .ant-select {
    width: 106px;
  }
}
