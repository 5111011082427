$grey-0: #0000000f;
$grey-1: #5a5757;
$grey-2: #fafafa;
$grey-3: #f5f5f5;
$grey-4: #f0f0f0;
$grey-5: #d9d9d9;
$grey-7: #8c8c8c;
$grey-12: #141414;

$green-6: #52c41a;

$white-1: #ffffff;

$warning: #df6420;

$red-1: #fff1f0;
$red-5: #ff4d4f;
$red-6: #f5222d;

$primary-1: #f0f7ff;
$primary-2: #d3e1f5;
$primary-6: #2b59c3;
$primary-7: #1a3d9c;
$primary-8: #0e2675;
$primary-10: #020929;

$text-primary: #000000d9;
$text-socondary: #00000073;
$text-socondary-2: #0000008c;
$text-grey: #00000040;
$grey-border: #00000026;
$divider: #0000000f;
$error: #ff4d4f;

$grey-shadow-1: rgba(0, 0, 0, 0.043);
