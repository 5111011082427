.hint-right {
  position: absolute;
  right: -136px;
  top: 33px;
  > .anticon {
    margin-right: 8px;
  }

  @media only screen and (max-width: 1279px) {
    position: static;
    margin-bottom: 5px;
  }
}
