.timer {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;

  .ant-btn-link {
    font-size: 12px;
    height: unset;
  }
}
