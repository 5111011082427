.select-box {
  .all-tag {
    left: 60px;
  }

  .label {
    left: 12px;
  }

  .select-list {
    min-width: 160px;
    .ant-select-selector {
      padding: 1px 25px 1px 60px !important;
    }

    &.all-selected {
      .ant-select-selection-overflow-item {
        display: none;
      }
    }
  }
}

.date-selector {
  .all-tag {
    left: 107px;
  }

  .label {
    left: 12px;
  }

  .select-list {
    min-width: 227px;
    .ant-select-selector {
      padding: 1px 25px 1px 107px !important;
    }

    &.all-selected {
      .ant-select-selection-overflow-item {
        display: none;
      }
    }
  }
}
