.security-check-content {
  width: 880px;

  .form-content {
    margin-left: 40px;
  }

  @media only screen and (max-width: 1279px) {
    width: 400px;
    margin: 0 16px;

    .form-content {
      width: 100%;
      margin-left: 0px;

      .bottom-links {
        margin-bottom: 110px;
      }
    }
  }
}
