.forgot-acc-form {
  width: 880px;

  .form__container,
  .links-container {
    margin-left: 40px;
  }

  .err-header {
    display: flex;
  }

  .forgot-pass {
    width: 400px;
  }

  .login-btn {
    margin-left: 40px;
  }

  .user-card {
    margin-left: 40px;
  }

  @media only screen and (max-width: 1279px) {
    width: 400px;

    .err-header {
      display: block;
    }

    .form__container {
      margin-left: 0;
      margin-bottom: 50px;
    }

    .links-container {
      margin-left: 0;
    }

    .login-btn {
      margin-left: 0;
    }

    .user-card {
      margin-left: 0;
      width: 100%;

      > div {
        div:nth-child(2) {
          p {
            max-width: 180px;
          }
        }
      }
    }
  }
}
