@import "../../styles/variables";

.password-requirements {
  display: flex;

  > ul {
    margin-top: 4px;
  }

  @media only screen and (max-width: 1279px) {
    display: block;

    > ul {
      margin-top: 0;
    }
  }

  span,
  li {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $text-socondary-2;
    list-style: none;
    display: flex;
    align-items: center;

    &.checked {
      color: $green-6;

      .anticon-check-circle {
        margin-right: 3.5px;
        color: $green-6;
        font-size: 10.5px;
      }
    }

    .dot {
      width: 4px;
      height: 4px;
      background-color: $text-socondary-2;
      border-radius: 50%;
      margin-right: 6px;
      margin-left: 4px;
    }
  }
}
