@import "../../styles/variables";

.user-management-filters {
  .roles {
    .select-box {
      .all-tag {
        left: 50px;
      }

      .select-list {
        .ant-select-selector {
          padding: 1px 25px 1px 50px !important;
        }
      }
    }
  }
}

.cell-elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .link-btn {
    color: $primary-6;
    cursor: pointer;
  }
}

.reason-modal {
  .ant-modal-close {
    top: 22px !important;
    right: 24px !important;
  }
}

.event-collapse {
  .ant-collapse {
    background-color: $white-1;

    .ant-collapse-header {
      align-items: center !important;
      padding: 24px 24px 16px 24px;
      flex-direction: row-reverse;

      .collapse-header {
        p {
          max-width: 230px;

          &.time {
            color: $text-socondary-2;
          }
        }
      }
    }

    .ant-collapse-content {
      border-top: 0px;

      .ant-collapse-content-box {
        padding: 0px;

        .content-box {
          width: 230px;

          .pre-line {
            word-break: break-all;
          }
        }
      }
    }
  }
}
