.error-page {
  margin: 0 88px;

  > .ant-row {
    justify-content: space-between;
    .error-page-left__container {
      width: 536px;

      .ant-btn {
        padding: 0;
        border: 0;
      }
    }
  }

  @media only screen and (max-width: 1279px) {
    margin: 0 16px;
    > .ant-row {
      justify-content: center;
      height: 100%;
      margin-top: 80px;

      .error-img {
        display: none;
      }

      .error-page-left__container {
        max-width: 536px;
        width: unset;
      }
    }
  }
}
