@import "../../../../styles/variables";

#coupon {
  display: none;
}

.disclamer {
  width: 870px;
  background-color: $grey-2;
  margin: 16px !important;

  @media only screen and (max-width: 1279px) {
    width: 400px;
  }

  p {
    background-color: $grey-2;
  }

  .ant-btn {
    height: 16px;

    .anticon {
      color: $text-primary;

      @media only screen and (max-width: 359px) {
        display: none;
      }
    }
  }
}

.past-payments-container {
  width: 870px;
}

.disclamer-text {
  height: calc(100% - 63px);
}

.how-to-pay__modal {
  @media only screen and (max-width: 767px) {
    top: 16px;
  }

  .pick-container {
    display: flex;

    @media only screen and (max-width: 599px) {
      display: block !important;

      img {
        width: 100%;
        max-width: 310px;
      }
    }
  }
  .ant-modal-content {
    padding: 0;

    .important-label {
      color: $primary-8;
    }

    .ant-modal-footer {
      border-top: 1px solid $divider;
    }
  }

  .ant-modal-close {
    display: none;
  }
}

@media print {
  .title,
  .footer-logged-in__container,
  .logged-header__container,
  .ant-tabs-nav,
  .main-info-card,
  .secondary-info-card,
  .ant-tabs-tab-btn,
  .table-page-content,
  .footer-text,
  .disclamer {
    display: none;
  }

  * {
    font-family: serif;
  }

  .footer-logged-in__container {
    border-top: 0 !important;
  }

  .ant-tabs-nav {
    &::before {
      border-bottom: 0 !important;
    }
  }

  .ant-tabs-tabpane {
    overflow-y: hidden !important;
  }

  #coupon {
    display: block !important;
    position: absolute;
    left: 10px;
    top: 10px;
    margin: 10px;
    width: 640px;

    div {
      &.column {
        display: block;
        float: left;
        width: 60%;
      }
    }

    dl {
      display: block;
      border-left: 1px solid #888888;
      line-height: 18px;

      &.column {
        float: left;
        width: 39%;
      }
    }

    p {
      display: block;
      margin: 0;
      line-height: 18px;

      &.data {
        clear: left;
      }
    }

    dt {
      display: block;
      clear: both;
      float: left;
      width: 125px;
      padding-left: 5px;
      line-height: 18px;
    }

    dd {
      float: left;
      margin: 0px;
      line-height: 18px;
    }

    .underline {
      width: 100px;
      border-bottom: 2px solid black;
      text-decoration: none;
    }

    .noemph {
      font-weight: normal;
      font-size: 12px;
    }

    .label {
      font-weight: bold;
    }
  }
}
