@import "../../../styles/variables";

.accounts {
  margin: 0 32px;

  .title {
    margin: 24px 0;

    @media only screen and (max-width: 1279px) {
      margin: 20px 0;
    }
  }

  @media only screen and (max-width: 1279px) {
    margin: 0 16px;

    .ant-table-content {
      overflow-x: hidden;

      .next-payment-col {
        flex-direction: column;
        align-items: end;

        .date-val {
          font-size: 12px;
          font-weight: 500;
          color: $text-socondary-2;
        }
      }
    }
  }
}

.account-view__container {
  right: 0;
  left: 0;
  top: 64px;
  bottom: 41px;
  z-index: 101;
  border-bottom: 1px solid $divider;

  @media only screen and (max-width: 1279px) {
    border-bottom: 0;

    .desktop-label {
      display: none;
    }
  }
}

.account-tabs {
  height: calc(100% - 80px);

  > .ant-tabs {
    height: 100%;

    > .ant-tabs-nav {
      background-color: #ffffff;

      @media only screen and (max-width: 1199px) {
        display: none;
      }
    }
  }

  .history-table-content {
    height: 100%;
    background-color: $white-1;

    .table__container {
      height: calc(100% - 105px);

      .ant-table-thead {
        > tr:nth-child(2) {
          position: relative;
          height: 45px;

          td,
          th {
            background-color: $white-1;

            &::before {
              width: 0 !important;
            }

            .balance-value {
              position: absolute;
              right: 16px;
              top: 12px;
              width: 1000px;

              .label {
                font-weight: 400;
              }
            }
          }
        }
      }
    }

    .ant-table-tbody {
      .ant-table-cell {
        padding: 10px 16px !important;
      }
    }

    .ant-table-summary {
      background-color: $grey-2;
      position: sticky;
      bottom: 0;
    }
  }
}

.ant-tabs-tabpane {
  background-color: $grey-2;
  overflow-y: auto;

  .tab-content {
    .cards-row {
      .main-info-card {
        width: 543px;

        .ant-card {
          min-height: 327px;
          margin-right: 16px;

          @media only screen and (max-width: 431px) {
            margin: 0 16px !important;
          }
        }
      }

      @media only screen and (max-width: 1279px) {
        flex-direction: column;
        align-items: center;

        .main-info-card {
          margin-bottom: 16px;
          width: 400px;

          .ant-card {
            max-width: 400px;
            margin-right: 0;
            .info-row {
              display: block;
              > .ant-col {
                max-width: 100%;
              }
            }
          }
        }
      }
    }

    .secondary-info-card {
      width: 327px;
      > .ant-card {
        min-height: 324px;

        @media only screen and (max-width: 431px) {
          margin: 0 16px;
        }
      }

      @media only screen and (max-width: 1279px) {
        width: 400px;
      }
    }

    .header-text {
      color: $primary-8;
    }
  }
}

.past-payments-table {
  .ant-table-thead {
    > tr {
      .ant-table-cell {
        padding: 6px 16px !important;

        &:nth-child(1):before {
          display: none;
        }
      }
    }
  }

  .table-footer {
    background-color: $grey-2;

    .ant-table-cell {
      padding: 6px 16px;
      font-weight: 500;
    }
  }
}
