@import "../../../styles/variables";

.logged-header__container {
  background-color: $grey-3;
  height: 64px;
  padding: 0 32px;

  @media only screen and (max-width: 1279px) {
    padding: 0 16px;
  }

  .logo {
    height: 24px;
  }

  .header-button {
    display: flex;
    height: 64px;
    padding: 21px 16px;
    border: unset;
    border-radius: 0;

    &.mobile-btn {
      display: none;
      cursor: pointer;

      @media only screen and (max-width: 1279px) {
        display: flex;
        height: unset;

        .anticon-down {
          font-size: 10px;
        }
      }
    }

    &.desktop-btn {
      @media only screen and (max-width: 1199px) {
        display: none;
      }
    }

    &.active {
      background-color: $primary-2;

      span {
        color: $primary-7;
      }
    }

    &.opened {
      background-color: $grey-4;
    }

    .counter-label {
      background-color: $red-5;
      color: $white-1 !important;
      border-radius: 100px;
      padding: 0 4.625px;
    }

    .user-header-label {
      max-width: 130px;
    }
  }
}
