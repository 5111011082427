@import "../../../../styles/variables";

.mobile-menu {
  .terms-items {
    .ant-btn {
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      height: 16px;
      letter-spacing: 0.5px;
      color: $text-socondary-2;
    }
  }
}
