@import "../../../styles/variables";

.footer-logged-in__container {
  bottom: 0;
  right: 0;
  left: 0;
  background-color: $grey-2;
  border-top: 1px solid $divider;
  display: flex;

  @media only screen and (max-width: 1279px) {
    display: none;
  }

  .footer-text {
    color: rgba(0, 0, 0, 0.55);
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    height: 16px;

    .ant-btn-link {
      padding: 4px 8px;
      color: $grey-7;
    }
  }
}
