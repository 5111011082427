.single-select {
  .label {
    left: 12px;
  }

  .ant-select-single {
    min-width: 125px;

    .ant-select-selector {
      padding: 1px 25px 1px 47px !important;
    }
  }
}
