@import "../../styles/variables";

.signup {
  width: 880px;

  .singup-left__container {
    border-right: 1px solid $grey-border;
    width: 50%;
  }

  .sign-right__container {
    padding-left: 40px;
    width: 50%;

    .unactive-step {
      color: $text-grey;
    }

    .active-step {
      color: $primary-10;
    }

    .link-step {
      color: $primary-6;
    }

    .ant-radio-group {
      width: 100%;

      > label {
        width: 50%;
        text-align: center;
      }
    }

    .tax-id__container {
      width: 154px;
    }

    .tax-id-hint {
      flex: 1;
      font-size: 12px;
      color: $text-socondary;
    }

    .ant-input-group-addon {
      border-radius: 4px;
    }
  }

  @media only screen and (max-width: 1279px) {
    > .ant-row {
      justify-content: center;

      .singup-left__container {
        display: none;
      }

      .sign-right__container {
        padding-left: 0;
        width: 400px;
        margin: 0 16px 110px;
      }
    }
  }
}
