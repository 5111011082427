@import "../../styles/variables";

.terms-modal {
  .ant-modal-content {
    padding: 0;

    .ant-modal-header {
      padding: 24px 32px 8px;
    }

    .ant-modal-body {
      @media only screen and (max-height: 719px) {
        height: 400px;

        > .ant-tabs {
          height: 100%;
        }
      }
    }

    .ant-tabs-tabpane {
      background-color: $white-1;

      .terms-modal-body {
        max-height: 500px;
      }
    }

    .ant-modal-footer {
      padding: 24px;
      margin-top: 0;

      border-top: 1px solid $divider;
    }
  }
}
