html {
  font-family: "Roboto", sans-serif !important;
  height: 100%;
}

#notification {
  position: absolute;
  top: 27px;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  z-index: 100;
}

#root {
  height: 100%;
}

.ant-app {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 1279px) {
  #notification {
    position: fixed;
    left: 16px;
    right: 16px;
  }
}
