.guest-header {
  padding-top: 32px;
  max-width: 880px;
  margin: auto;

  @media only screen and (max-width: 1279px) {
    padding: 16px 16px 0;
    max-width: unset;
  }
}
