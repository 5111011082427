@import "../../styles/variables";

.sidebar__container {
  .info-container {
    width: 378px;
    height: 100%;
  }

  width: 378px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: $white-1;
  box-shadow: -5px 0px 4px 0px rgba(0, 0, 0, 0.15);
  height: 100%;
  z-index: 999;

  @media only screen and (max-width: 767px) {
    width: unset;
    max-width: 378px;
  }

  .sidebar-header {
    border-bottom: 1px solid $divider;

    .close-button {
      height: 28px;
      width: 28px;
      min-width: 28px;
      padding: 3px;
    }

    .title {
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      max-width: 225px;
    }
  }

  .sidebar-content {
    height: calc(100% - 78px);

    .ant-form-item-label {
      display: none;
    }

    .role-selector {
      width: 140px;

      .ant-select {
        width: 100%;
      }
    }

    .sidebar-body {
      height: calc(100% - 136px);
      overflow-y: auto;

      &.user-content {
        height: calc(100% - 80px);
      }

      .ant-tabs {
        height: 100%;

        .ant-tabs-nav {
          padding-left: 24px;
        }
        .ant-tabs-tabpane {
          background-color: $white-1;
        }
      }
    }

    .sidebar-footer {
      border-top: 1px solid $divider;

      .ant-btn {
        min-width: 120px;
        max-width: 144px;
      }
    }
  }
}
