@import "../../styles/variables";

.profile__container {
  width: 816px;

  @media only screen and (max-width: 1279px) {
    width: 100%;
    > .ant-row {
      flex-direction: column;
      align-items: center;

      .card {
        margin: 0 16px 16px;

        @media only screen and (max-width: 431px) {
          width: calc(100% - 32px);

          &.right {
            .text {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .card {
    width: 400px;

    &.right {
      height: 128px;
      margin-left: 16px;
    }

    &.card-data {
      max-height: 472px;

      &.left {
        min-height: 422px;
      }

      .ant-card-body {
        height: 100%;

        .header {
          color: $primary-8;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .item {
          width: 100%;

          .bordered {
            border-bottom: 1px solid $divider;
          }

          .value__container {
            max-width: 235px;

            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .label {
          letter-spacing: 0.5px;
          color: $text-socondary;
        }
      }
    }
  }
}
