.ant-modal-title {
  .header {
    margin-top: 4px;
    font-size: 24px;
    line-height: 32px;

    @media only screen and (max-width: 359px) {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

.ant-modal-close {
  top: 30px !important;
  inset-inline-end: 32px !important;
}
